<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--
    //begin::Activities
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      //begin::drawer toggle
      <div
        class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
        id="kt_activities_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotone/Media/Equalizer.svg" />
        </span>
      </div>
      //end::drawer toggle
    </div>
    //end::Activities

    //begin::Quick links
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      //begin::Menu
      <div
        class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotone/Layout/Layout-4-blocks.svg" />
        </span>
      </div>
      <KTQuickLinksMenu></KTQuickLinksMenu>
      //end::Menu
    </div>
    //end::Quick links

    //begin::Chat
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      //begin::Menu wrapper
      <div
        class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
        id="kt_drawer_chat_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotone/Communication/Group-chat.svg" />
        </span>

        <span
          class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"
        >
        </span>
      </div>
      //end::Menu wrapper
    </div>
    //end::Chat

    //begin::Notifications
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      //begin::Menu
      <div
        class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotone/Code/Compiling.svg" />
        </span>
      </div>
      <KTNotificationsMenu></KTNotificationsMenu>
      //end::Menu
    </div>
    //end::Notifications
    -->

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <!--old class => class="cursor-pointer symbol symbol-30px symbol-md-40px"-->
      <div
        class="text-muted font-weight-bold font-size-base d-none d-md-inline me-1"
      >
        {{ $gettext("Hi,") }}
      </div>
      <div
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline me-5"
      >
        {{ currentUser.name }}
      </div>
      <div
        class="symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img src="favicon.ico" alt="metronic" />
      </div>
      <KTUserMenu :user="currentUser"></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotone/Text/Toggle-Right.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";
//import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
//import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { User } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "topbar",
  components: {
    //KTNotificationsMenu,
    //KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const currentUser = computed<User>(
      () =>
        store.state.AuthModule.user ?? {
          name: "",
          surname: "",
          code: "",
          magicLink: "",
        }
    );

    watch(currentUser, (newValue, oldValue) => {
      // newValue will be the updated value of currentUser
      // you can use this value in your component's code
    });

    return {
      currentUser,
    };
  },
});
</script>
