import gettext from "../../gettext";

const { $gettext } = gettext;

const DocMenuConfig: object = [
  {
    pages: [
      {
        heading: $gettext("Dashboard"),
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: $gettext("My orders"),
        route: "/my-orders",
        svgIcon: "media/icons/duotone/Communication/Dial-numbers.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: $gettext("Printee orders"),
        route: "/printee-orders",
        svgIcon: "media/icons/duotone/Design/Image.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: $gettext("Invoices"),
        route: "/invoices",
        svgIcon: "media/icons/duotone/Shopping/Money.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
