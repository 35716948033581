
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useGettext } from "vue3-gettext";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const gettext = useGettext();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      gettext,
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
    };
  },
});
