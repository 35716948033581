
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useGettext } from "vue3-gettext";

export default defineComponent({
  name: "kt-user-menu",
  props: {
    user: Object,
  },
  components: {},
  setup() {
    const router = useRouter();
    const gettext = useGettext();
    const store = useStore();

    const curLang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";
    gettext.current = curLang;
    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
