
import { defineComponent, computed, watch } from "vue";
//import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
//import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { User } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "topbar",
  components: {
    //KTNotificationsMenu,
    //KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const currentUser = computed<User>(
      () =>
        store.state.AuthModule.user ?? {
          name: "",
          surname: "",
          code: "",
          magicLink: "",
        }
    );

    watch(currentUser, (newValue, oldValue) => {
      // newValue will be the updated value of currentUser
      // you can use this value in your component's code
    });

    return {
      currentUser,
    };
  },
});
