
//TODO https://www.npmjs.com/package/vue-clipboard3
//import useClipboard from 'vue-clipboard3'
//@click="saveToClipboard(spsDynamic)"
import { useStore } from "vuex";
import { defineComponent, computed, watch } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { User } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    //let spsName = "error";
    //let spsDynamic = "error";
    //spsName = user.code;
    //spsDynamic = user.magicLink;
    const qrcode = "";
    return {
      tool: false,
      text: "Copied!",
      //spsName,
      //spsDynamic,
      qrcode,
    };
  },
  setup() {
    const store = useStore();
    const currentUser = computed<User>(
      () =>
        store.state.AuthModule.user ?? {
          name: "",
          surname: "",
          code: "",
          magicLink: "",
        }
    );
    watch(currentUser, () => {
      // this command is very usefull, since empty arrow functions are not allowed :)
      // also you can get new and old from this function (newValue, oldValue) => {}
    });
    return {
      store,
      currentUser,
    };
  },
  methods: {
    showFinishedChanged: function () {
      this.store.dispatch(Actions.SHOW_FINISHED_CHANGED);
    },
    copyToClipboard: async function (str: string) {
      navigator.clipboard.writeText(str);
      this.tool = true;
      setTimeout(() => (this.tool = false), 1000);
    },
    missingBS5feature: () => {
      //TODO add animation if required. sibling show ignores fade property
      // or simply check if BS 5 already has this feature. Should be multi tab
      // loading which was supported in BS 3.4.1 and they remove it. :facepalm:
      document.querySelectorAll('a[data-bs-toggle="tab"]').forEach((t) => {
        t.addEventListener("show.bs.tab", () => {
          const targetClass = (t as HTMLElement).dataset.bsTarget;
          const pane = document.querySelector("#tabs_2 " + targetClass);
          const sibling = document.querySelector("#tabs_2 .active");
          if (sibling != null && pane != null) {
            sibling.classList.remove("show");
            sibling.classList.remove("active");
            pane.classList.add("show");
            pane.classList.add("active");
          }
        });
      });
    },
  },
  created() {
    this.store
      .dispatch(Actions.QRCODE)
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => {
          if (reader.result != null) {
            this.qrcode = reader.result.toString();
          }
        };
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
