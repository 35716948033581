<template>
  <!--begin::Toolbar-->
  <div class="toolbar" id="kt_toolbar">
    <!--begin::Container-->
    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex align-items-center flex-wrap lh-1">
        <!--begin::Title-->
        <h1
          class="d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5"
        >
          {{ title }}
        </h1>
        <!--end::Title-->

        <span
          v-if="breadcrumbs"
          class="h-20px border-gray-200 border-start mx-4"
        ></span>

        <!--begin::Breadcrumb-->
        <ul
          v-if="breadcrumbs"
          class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
        >
          <li class="breadcrumb-item pe-3">
            <router-link to="/dashboard" class="text-muted text-hover-primary">
              Home
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-200 w-5px h-2px"></span>
          </li>
          <template v-for="(item, index) in breadcrumbs" :key="index">
            <li class="breadcrumb-item text-muted">
              {{ item }}
            </li>
            <li class="breadcrumb-item">
              <span class="bullet bg-gray-200 w-5px h-2px"></span>
            </li>
          </template>
          <li class="breadcrumb-item pe-3 text-dark">
            {{ title }}
          </li>
        </ul>
        <!--end::Breadcrumb-->
        <!--begin::Seperator-->
        <span class="h-20px border-gray-200 border-start ms-3 mx-2 me-5"></span>
        <!--end::Seperator-->
        <!--begin::Seperator-->
        <button
          type="button"
          class="btn btn-light-warning font-weight-bolder btn-sm font-weight-bolder"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_1"
        >
          {{ $gettext("QR Code") }}
        </button>
        <!--end::Seperator-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->
      <div class="d-flex align-items-center py-1">
        <!--begin::Wrapper-->
        <div class="me-4">
          <div
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              value="true"
              v-model="this.store.state.PacketsModule.showFinished"
              @change="showFinishedChanged()"
            />
            <label
              class="form-check-label font-weight-bolder font-size-sm me-3"
              for="flexSwitchChecked"
            >
              {{ $gettext("Show finished orders") }}
            </label>
          </div>
        </div>
        <!--end::Wrapper-->
        <!--//begin::Wrapper
        <div class="me-4">
          //begin::Menu
          <a
            href="#"
            class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="media/icons/duotone/Text/Filter.svg" />
            </span>
            Filter
          </a>

          <Dropdown1></Dropdown1>
          //end::Menu
        </div>
        //end::Wrapper-->

        <!--begin::Buttons-->
        <div class="card-toolbar">
          <ul class="nav">
            <li class="nav-item" @click="missingBS5feature">
              <a
                class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 active"
                data-bs-toggle="tab"
                data-bs-target=".tab1"
                role="button"
                aria-expanded="false"
                aria-controls="first"
                >{{ $gettext("Month") }}</a
              >
            </li>
            <li class="nav-item" @click="missingBS5feature">
              <a
                class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                data-bs-toggle="tab"
                data-bs-target=".tab2"
                role="tab"
                aria-expanded="false"
                aria-controls="second"
                >{{ $gettext("Week") }}</a
              >
            </li>
            <li class="nav-item" @click="nextStep">
              <a
                class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                data-bs-toggle="tab"
                data-bs-target=".tab3"
                role="tab"
                aria-expanded="false"
                aria-controls="third"
                >{{ $gettext("Day") }}</a
              >
            </li>
          </ul>
        </div>
        <!--end::Buttons-->
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Toolbar-->
  <!---begin::Modals-->
  <div class="modal fade" tabindex="-1" id="kt_modal_1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $gettext("Unique QR code and link for account: ") }}
            {{ this.currentUser.code }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-danger ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-center">
          <img :src="qrcode" />
        </div>
        <div class="modal-footer justify-content-center">
          <p>{{ currentUser.magicLink }}</p>
          <div
            class="btn btn-icon btn-sm btn-active-primary ms-2"
            @click="copyToClipboard(currentUser.magicLink)"
          >
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="media/icons/duotone/General/Copy.svg" />
            </span>
          </div>
          <!-- TODO add animations to copied text -->
          <span v-show="tool">{{ text }}</span>
        </div>
      </div>
    </div>
  </div>
  <!---end::Modals-->
</template>
<script lang="ts">
//TODO https://www.npmjs.com/package/vue-clipboard3
//import useClipboard from 'vue-clipboard3'
//@click="saveToClipboard(spsDynamic)"
import { useStore } from "vuex";
import { defineComponent, computed, watch } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { User } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    //let spsName = "error";
    //let spsDynamic = "error";
    //spsName = user.code;
    //spsDynamic = user.magicLink;
    const qrcode = "";
    return {
      tool: false,
      text: "Copied!",
      //spsName,
      //spsDynamic,
      qrcode,
    };
  },
  setup() {
    const store = useStore();
    const currentUser = computed<User>(
      () =>
        store.state.AuthModule.user ?? {
          name: "",
          surname: "",
          code: "",
          magicLink: "",
        }
    );
    watch(currentUser, () => {
      // this command is very usefull, since empty arrow functions are not allowed :)
      // also you can get new and old from this function (newValue, oldValue) => {}
    });
    return {
      store,
      currentUser,
    };
  },
  methods: {
    showFinishedChanged: function () {
      this.store.dispatch(Actions.SHOW_FINISHED_CHANGED);
    },
    copyToClipboard: async function (str: string) {
      navigator.clipboard.writeText(str);
      this.tool = true;
      setTimeout(() => (this.tool = false), 1000);
    },
    missingBS5feature: () => {
      //TODO add animation if required. sibling show ignores fade property
      // or simply check if BS 5 already has this feature. Should be multi tab
      // loading which was supported in BS 3.4.1 and they remove it. :facepalm:
      document.querySelectorAll('a[data-bs-toggle="tab"]').forEach((t) => {
        t.addEventListener("show.bs.tab", () => {
          const targetClass = (t as HTMLElement).dataset.bsTarget;
          const pane = document.querySelector("#tabs_2 " + targetClass);
          const sibling = document.querySelector("#tabs_2 .active");
          if (sibling != null && pane != null) {
            sibling.classList.remove("show");
            sibling.classList.remove("active");
            pane.classList.add("show");
            pane.classList.add("active");
          }
        });
      });
    },
  },
  created() {
    this.store
      .dispatch(Actions.QRCODE)
      .then((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => {
          if (reader.result != null) {
            this.qrcode = reader.result.toString();
          }
        };
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
</script>
